import { postRequest } from "app/axiosClient";
import {
  appointmentListSuccess,
  resetList,
  showAppLoader,
  hideAppLoader,
  updatePgNumber,
  setAppointmentId,
  setDateRange,
} from "./AppointmentsSlice";
import { getEquipmentListAPI } from "features/Equipment/EquipmentList/EquipmentsAPI";
import { showNotification } from "features/CommonComponents/NofificationMessages/NofificationMessagesSlice";
import { getTasksFromServiceTemplateByEquipmentIdApi } from "features/Equipment/EquipmentDetails/EquipmentDetailsAPI";

export const getAppointListAPI = (payload, forceFetch) => (dispatch) => {
  try {
    dispatch(showAppLoader());
    return postRequest(`api/Appointment/List?forceFetch=${forceFetch}`, payload)
      .then((res) => {
        dispatch(appointmentListSuccess(res.data));
        dispatch(hideAppLoader());
        return res.data;
      })
      .catch((e) => {
        dispatch(hideAppLoader());
        return false;
      });
  } catch (e) {
    dispatch(hideAppLoader());
    return false;
  }
};

export const markAllServiceTemplatesAsCompleted = (input) => (dispatch) => {
  try {
    dispatch(showAppLoader());
    return postRequest(
      `api/Tasks/CompleteAllEquipmentTasks?appointmentNbr=${input?.appointmentNbr}&serviceOrderType=${input?.serviceOrderType}&serviceOrderNumber=${input?.serviceOrderNumber}`
    )
      .then((res) => {
        var path = {
          appointmentNbr: input?.appointmentNbr,
          customerId: input?.customer,
          Tagsearch: null,
          Manufsearch: null,
        };
        dispatch(getEquipmentListAPI(path));
        if (input?.equipmentId) {
          dispatch(
            getTasksFromServiceTemplateByEquipmentIdApi(
              input?.appointmentNbr,
              input?.equipmentId
            )
          );
        }
        dispatch(hideAppLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.EquipmentTaskStatusUpdatedSuccessfully",
            type: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(hideAppLoader());
        dispatch(
          showNotification({
            show: true,
            messageId: "Equipment.EquipmentStatusUpdateFailed",
            type: "error",
          })
        );
      });
  } catch (e) {
    dispatch(hideAppLoader());
    dispatch(
      showNotification({
        show: true,
        messageId: "Equipment.EquipmentStatusUpdateFailed",
        type: "error",
      })
    );
  }
};

export const resetAppointmentstate = () => (dispatch) =>
  Promise.all([dispatch(resetList())]);

export const setPageNo = (pgNumber) => (dispatch) =>
  Promise.all([dispatch(updatePgNumber(pgNumber))]);

export const setSelectedAppointment = (appointmentId) => (dispatch) =>
  Promise.all([dispatch(setAppointmentId(appointmentId))]);

export const setRange = (range) => (dispatch) =>
  Promise.all([dispatch(setDateRange(range))]);
