import { logIn, showLoader, hideLoader } from "./authenticationSlice";
import { postRequest } from "app/axiosClient";
import { message } from "antd";
import { showNotification } from "../CommonComponents/NofificationMessages/NofificationMessagesSlice";
export const getTokenApi = (payload) => (dispatch) => {
  try {
    dispatch(showLoader());
    // postRequest(`api/Login/GetToken?userName=${userName}&password=${password}`)
    postRequest(`api/Login/GetToken`, payload)
      .then((res) => {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("token_type", res.data.token_type);
        localStorage.setItem("validity", res.data.expires_in);
        localStorage.setItem("isAuthenticated", !!res.data);
        localStorage.setItem("employeeId", res.data.employeeID);
        localStorage.setItem("employeefullname", res.data.fullName);
        localStorage.setItem("userName", res.data.login);
        const expiresInSeconds = res.data.expires_in;
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + expiresInSeconds * 1000;
        const expirationDateTime = new Date(expirationTime);
        localStorage.setItem("expires", expirationDateTime);
        dispatch(logIn(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(
          showNotification({
            show: true,
            messageId: "Authentication.InvalidUsername",
            type: "error",
          })
        );
        dispatch(hideLoader());
      });
  } catch {
    dispatch(
      showNotification({
        show: true,
        messageId: "Authentication.InvalidUsername",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};

export const getTokenByRefreshApi = (refreshToken, userName) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(
      `api/Login/GetTokenByRefreshToken?refresh_token=${refreshToken}&userName=${userName}`
    )
      .then((res) => {
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("token_type", res.data.token_type);
        localStorage.setItem("validity", res.data.expires_in);
        localStorage.setItem("isAuthenticated", !!res.data);
        localStorage.setItem("employeefullname", res.data.fullName);
        localStorage.setItem("userName", res.data.login);
        const expiresInSeconds = res.data.expires_in;
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + expiresInSeconds * 1000;
        const expirationDateTime = new Date(expirationTime);
        localStorage.setItem("expires", expirationDateTime);
        dispatch(logIn(res.data));
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(
          showNotification({
            show: true,
            messageId: "Authentication.RefreshToken",
            type: "error",
          })
        );
        dispatch(hideLoader());
      });
  } catch {
    dispatch(
      showNotification({
        show: true,
        messageId: "Authentication.RefreshToken",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};
export const logOutApi = (token) => (dispatch) => {
  try {
    dispatch(showLoader());
    postRequest(`api/Login/LogOut?token=${token}`)
      .then((res) => {
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(
          showNotification({
            show: true,
            messageId: "Authentication.UnableLogOut",
            type: "error",
          })
        );
        dispatch(hideLoader());
      });
  } catch {
    dispatch(
      showNotification({
        show: true,
        messageId: "Authentication.UnableLogOut",
        type: "error",
      })
    );
    dispatch(hideLoader());
  }
};
