import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appointmentList: [],
  loading: false,
  pageNo: 1,
  selectedId: null,
  redStartDate: null,
  redEndDate: null,
  selectedTab: null,
  selectedScroll: null,
};

const AppointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    appointmentListSuccess(state, action) {
      state.appointmentList = [...state.appointmentList, ...action.payload];
    },
    updateAppointmentSuccess(state, action) {
      if (action.payload.length > 0) {
        let list = state.appointmentList.map((item) =>
          item.noteID === action.payload[0].noteID ? action.payload[0] : item
        );

        state.appointmentList = list.length == 0 ? action.payload : list;
      }
    },
    resetList(state) {
      state.appointmentList = [];
    },
    showAppLoader(state) {
      state.loading = true;
    },
    hideAppLoader(state) {
      state.loading = false;
    },
    updatePgNumber(state, action) {
      state.pageNo = action.payload;
    },
    setAppointmentId(state, action) {
      state.selectedId = action.payload;
    },
    setDateRange(state, action) {
      state.redStartDate = action.payload.startDate;
      state.redEndDate = action.payload.endDate;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setSelectedScroll(state, action) {
      state.selectedScroll = action.payload;
    },
  },
});

export const {
  appointmentListSuccess,
  updateAppointmentSuccess,
  resetList,
  showAppLoader,
  hideAppLoader,
  updatePgNumber,
  setAppointmentId,
  setDateRange,
  setSelectedTab,
  setSelectedScroll,
} = AppointmentsSlice.actions;
export default AppointmentsSlice.reducer;
